<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "containers",
              "add-user-to-container",
              "add-user-to-container"
            )
          }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('containers', 'add-user-to-container', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul class="clebex-item-section pill" v-if="userList && userList.length">
      <li
        class="clebex-item-section-item"
        v-for="user in userList"
        :key="user.id"
      >
        <button class="clebex-item-content-wrapper" @click="selectUser(user)">
          <span class="label">
            <span class="highlight">{{ user.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item"
              v-if="userList && users.filter((item) => item === user.id).length"
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "ContainersAddUser",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
    };
  },
  created() {
    this.setPaginationData(null);
    // this.setUsersList(null);
    this.getUsersList();
    this.setUserContainers([]);
    this.setAllUsers(false);
  },
  computed: {
    ...mapState("containers", ["listOfUsers"]),
    ...mapState("companyNotifications", ["users"]),
    userList() {
      const query = this.searchQuery;
      if (this.listOfUsers && this.listOfUsers.length) {
        if (query && query.length > 1) {
          return this.listOfUsers.filter(
            (item) =>
              item.first_name.toLowerCase().includes(query.toLowerCase()) ||
              item.last_name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.listOfUsers;
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions("containers", [
      "getUsersList",
      "setSelectedUser",
      "setUsersList",
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    ...mapActions("companyNotifications", [
      "setUsers",
      "setUserContainers",
      "setAllUsers",
      "setSendTo",
    ]),
    selectUser(user) {
      if (this.users.includes(user.id)) {
        this.users.splice(this.users.indexOf(user.id), 1);
      } else {
        this.users.push(user.id);
      }
      this.setSendTo(null);
      if (this.users.length) {
        this.setSendTo(
          this.displayLabelName("notifications.notifications.users") +
            ": " +
            this.listOfUsers
              .filter((c) => this.users.includes(c.id))
              .map((c) => c.first_name + " " + c.last_name)
              .join(", ")
        );
      }
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
